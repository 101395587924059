import {createRouter, createWebHistory} from '@ionic/vue-router';
import Login from '../views/Login.vue'
import Test from '../views/Test.vue'
import store from '@/store';
import authHandler from "./authHandler";
import TestIntro from "../views/TestIntro";
import AccessCode from "../views/AccessCode";
import UserInfo from "../views/UserInfo";
import Result from "../views/Result";
import Logout from "../views/Logout";
import Payment from "../views/Payment";
import constant from "../constant";
import cookie from "../utils/cookie";
import RouteHandler from "./routeHandler";
import responseHandler from "../store/responseHandler";
import iframeMessageUtils from "../utils/iframeMessageUtils";


const routes = [
    {
        path: '/',
        name: 'Start',
        component: Login
    },
    {
        path: '/access-code/',
        name: 'AccessCode',
        component: AccessCode
    },
    {
        path: '/user-info/',
        name: 'UserInfo',
        component: UserInfo
    },
    {
        path: '/payment/',
        name: 'Payment',
        component: Payment
    },
    {
        path: '/test-intro/',
        name: 'TestIntro',
        component: TestIntro
    },
    {
        path: '/test/',
        name: 'Test',
        component: Test,
    },
    {
        path: '/results/',
        name: 'Result',
        component: Result
    },
    {
        path: '/logout/',
        name: 'Logout',
        component: Logout
    },
]

const router = createRouter({

    history: createWebHistory(process.env.VUE_APP_BASE_PATH),
    routes
})


/* **** router guard ***** */
router.beforeEach(async (to, from, next) => {


    store.dispatch('updateLoadStatus');
    // This only happening, when the app is load initially
    if (store.state.initial_load) {
        // check quiz id exists
        let payload = setPayload(to, cm_hash_string);
        // handling of empty or null quiz id
        if (payload == null || payload.quiz_id == undefined || payload.quiz_id == null || payload.quiz_id.trim() == '' || payload.quiz_id.trim() == 'null') {
            __displayGlobalErrorPage(constant.ERROR_MISSING_PARAM);
            next();
            return false;
        }

        store.dispatch('Test/setQuizId', payload.quiz_id);
        store.dispatch('Test/setGroupType', payload.group_type);


        // get auth_token from store
        let token = store.state.Auth.token;
        let iframe_mode = store.state.iframe_mode;
        if (iframe_mode) {
            // set attempts for iframe mode
            store.dispatch('setIframeAttempts', payload.attempts);

        } else {
            let auth_grant_failed = false;
            //else get auth token
            auth_grant_failed = await authHandler.grantAuth(payload, token)
                .catch((error) => {

                    responseHandler.handleError(error);
                    return true;
                });
            if (auth_grant_failed) {
                next();
                return false;
            }
        }

        // Load initial test data
        try {
            // get theme inforamtion
            try {
                await store.dispatch('Test/initState', payload);
            } catch (err) {
                let error_code = err.response.data.error_code;
                if (error_code == constant.ERROR_NEW_TEST_DETECTED) {
                    // WHEN new test detected, then reset cookie and restart test with request parameters
                    if (iframe_mode) {
                        __displayGlobalErrorPage(constant.ERROR_RETRY_TEST);
                        const {deleteIframeCookie} = iframeMessageUtils()
                        deleteIframeCookie(payload.quiz_id);
                        next();
                        return false;
                    } else {
                        __restartTest(payload);
                    }
                } else if (error_code == constant.ERROR_TEST_LIMIT_REACHED) {
                    // WHEN show test limit reached error, then remove cookie
                    let cookie_name = authHandler.getTokenCookieName(payload.quiz_id);
                    cookie.remove(cookie_name);
                    throw err;
                } else {
                    throw err;
                }
            }
            // Force google analytics to populate start URl

            let current_state = store.state.Test.test_state; // get update state form store
            // Validate Attempts if required
            if (to.name !== 'Result') {

                // if current state is not completed, then check attempts
                if (__validateAttempts(payload) == false) {
                    __displayGlobalErrorPage(constant.ERROR_TOO_MANY_ATTEMPTS);
                }
            }

            /**
             * When the state of test is completed, but a user request to start new test,
             * then grab all query parameters and redirect to the initial page.
             * This is not applied when result page is setup from reopen URL
             */
            if (store.state.load_from_server == false
                && current_state == constant.STATUS_TEST_COMPLETE
                && to.name == 'Start') {

                if (iframe_mode) {
                    nextRoute(next, payload, current_state);
                } else {
                    __restartTest(payload);
                }
            } else {
                // send GTM event for starting test
                __sendGoogleTagManagerTestEvent();

                // Load the initial page to display
                nextRoute(next, payload, current_state);
            }
        } catch (error) {

            store.dispatch('Error/setMessageFromAPI', error);
            store.dispatch('Error/displayFullPageError')
            next();
        }

    } else {
        next();
    }
});


function nextRoute(next, payload, current_state) {
    const routeHandler = new RouteHandler();
    let route_name = routeHandler.getNextRouteName(current_state);
    let route_query = {quiz: payload.quiz_id};

    if (payload.is_test_preview !== null && payload.is_test_preview == 1) {
        route_query.preview = 1;
    }

    if (payload.group_type == constant.GROUP_TYPE_LINK) {
        next({name: route_name, query: route_query});
    } else {

        route_query.type = 'rg';
        next({name: route_name, query: route_query});
    }
}


function setPayload(to, request_params) {
    let payload = {
        quiz_id: null,
        group_type: null,
        cm_user_id: null,
        access_code: null,
        access_token: null,
        cm_return_url_params: null,
        first_name: null,
        last_name: null,
        email: null,
        resume_params: null,
        attempts: null, // this is only available when iframe mode is on
        resumed_via_link: false,
        trk: null, // tracking from server. Mainly used for preview mode return place,
        is_test_preview: null
    };

    let request_obj = null;
    if (process.env.VUE_APP_MODE == 'development_node') {
        request_obj = to.query;
    } else {
        //decrypt request param.
        let extract_string = request_params.substring(5, request_params.length - 5);
        let request_params_decrypt = atob(extract_string);
        request_obj = JSON.parse(request_params_decrypt);
    }


    // set payload
    if (request_obj['quiz'] ) {
        payload.quiz_id = request_obj['quiz'];

        // just double check if type is provided
        if(request_obj['type'] === 'rg'){
            payload.group_type = constant.GROUP_TYPE_GROUP;
        }else {
            payload.group_type = constant.GROUP_TYPE_LINK;
        }

    } else if (request_obj['test_id']) {
        payload.quiz_id = request_obj['test_id'];
        payload.group_type = constant.GROUP_TYPE_GROUP;
    } else {
        return null;
    }

    // set test_preveiw
    payload.is_test_preview = request_obj['preview'] ?? null;


    if (request_obj['trk']) {
        payload.trk = request_obj['trk'];
        store.dispatch('setTrackFromAdminApp', payload.trk);
    }

    // Sanitize keywords from quiz_id
    let quiz_id = payload.quiz_id.replace(/(\s)*Copy/g, "");
    quiz_id = quiz_id.replace(/(\s)*Copied/g, "");
    quiz_id = quiz_id.replace(/(\s)*Direct Link/g, "");
    quiz_id = quiz_id.replace(/(\s)*Embed Test/g, "");
    payload.quiz_id = quiz_id;


    payload.cm_user_id = request_obj['cm_user_id'] ?? null;
    payload.access_code = request_obj['cm_access_list_item'] ?? null; //get cm_user_id from the url;
    payload.access_token = request_obj['access_token'] ?? null;
    payload.first_name = request_obj['cm_fn'] ?? null;
    payload.last_name = request_obj['cm_ln'] ?? null;
    payload.email = request_obj['cm_e'] ?? null;
    payload.cm_return_url_params = __getCmReturnParams(request_obj);
    payload.iframe_token = request_obj['iframe_token'] ?? null;
    payload.auth_token = request_obj['auth'] ?? null;
    payload.attempts = request_obj['attempts'] ?? null;

    if (request_obj['resumeserial'] != null) {
        payload.resume_params = {
            'resumeserial': request_obj['resumeserial'],
            'tsg': request_obj['tsg'] ?? null,
            'oml': request_obj['oml'] ?? null,
            'ida': request_obj['ida'] ?? null,
        }
        //When resume params detected, delete auth_token cookie
        let auth_cookie_name = authHandler.getTokenCookieName(payload.quiz_id);
        cookie.remove(auth_cookie_name);
        payload.resumed_via_link = true
    }

    if (payload.auth_token != null) {
        // if auth token is passed from request, then store into Cookie
        store.dispatch('Auth/setToken', payload.auth_token);
        store.dispatch('setLoadFromServer');
    }

    if (payload.iframe_token != null) {
        // if auth token is pass from request, as iframe_token store it into Cookie and skipped the auth token endpoint
        store.dispatch('Auth/setToken', payload.iframe_token);
        store.dispatch('setIframeMode');
    }

    let user_data = {
        first_name: payload.first_name,
        last_name: payload.last_name,
        email: payload.email,
        login_email: payload.email,
    }

    store.dispatch('setOfflineStatus', constant.STATUS_ONLINE);
    store.dispatch('User/setUserInfo', user_data);
    store.dispatch('User/setAccessCode', payload.access_code);


    // set up restart URL
    if (payload.group_type == constant.GROUP_TYPE_LINK) {
        let restart_url = process.env.VUE_APP_START_URL + '?' + __getAllParamsForRestartTest(payload);
        store.dispatch('setRestartUrl', restart_url);
    } else {
        let restart_url = constant.STUDENT_INTERFACE_URL;
        store.dispatch('setRestartUrl', restart_url);
    }

    return payload;
}


function __displayGlobalErrorPage(error_code) {
    let language_set = store.getters.languageSet;
    let error_message = language_set[error_code] ?? constant.ERROR_MESSAGES[error_code];
    let error = {
        type: 'error',
        error_code: error_code,
        message: error_message,
        field: null
    }
    store.dispatch('Error/setMessage', {error: error});
    store.dispatch('Error/displayFullPageError');
}

function __restartTest(payload) {
    // remove cookie
    let cookie_name = authHandler.getTokenCookieName(payload.quiz_id);
    cookie.remove(cookie_name);


    // redirect to initial page
    let restart_url = store.state.restart_url;
    window.location.replace(restart_url);
}


function __sendGoogleTagManagerTestEvent() {
    // Force google analytics to populate start URl
    // send GTM information
    let group_type = store.getters.groupType;
    let event = 'Link-start';
    if (group_type == constant.GROUP_TYPE_GROUP) {
        event = 'Group-start';
    }

    let upgraded = 'free';
    const theme = store.getters.theme;
    if (theme != null && theme.show_ads != true) {
        upgraded = 'paid'
    }
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
        'event': event,
        'upgraded': upgraded
    });
}


function __getCmReturnParams(obj) {
    /* Set default object as url object. If request object (GET, POST) is not null,
        then change target object as request object */
    let result = '';
    for (const [key, value] of Object.entries(obj)) {
        if (new RegExp('^cm_return_(.)+').test(key)) {
            let param = '&amp;' + key + '=' + value;
            result = result + param;
        }
    }
    return result;
}


function __getAllParamsForRestartTest(payload) {

    let result = '';
    let skip_ampersand = true;

    for (const [key, value] of Object.entries(payload)) {

        let param = '';
        let found = false;
        if (value !== null && value !== '') {
            switch (key) {
                case 'quiz_id':
                    param = 'quiz=' + value;
                    found =true;
                    break;
                case 'cm_user_id':
                    param = 'cm_user_id=' + value;
                    found =true;
                    break;
                case 'access_code':
                    param = 'cm_access_list_item=' + value;
                    found =true;
                    break;
                case 'first_name':
                    param = 'cm_fn=' + value;
                    found =true;
                    break;
                case 'last_name':
                    param = 'cm_ln=' + value;
                    found =true;
                    break;
                case 'email':
                    param = 'cm_e=' + value;
                    found =true;
                    break;
                case 'cm_return_url_params':
                    param = value.replace(/\&amp;/g, '&');
                    found =true;
                    skip_ampersand = true;
                    break;
            }

            if(found) {
                if (skip_ampersand) {
                    result = result + param;
                    skip_ampersand = false;
                } else {
                    result = result + '&' + param;
                }
            }
        }

    }

    return result;
}


function __validateAttempts(payload) {
    let login_settings = store.state.Test.settings.login;
    let preview_mode = store.getters.previewMode;
    let group_type = store.getters.groupType;
    if (group_type == constant.GROUP_TYPE_LINK && preview_mode == false && payload.resumed_via_link == false && login_settings.attempts_allowed != null) {
        // if the attempts started by resume link, by pass the attempts validation


        //get attempts count from cookie
        let cookie_name = constant.CACHE_ATTEMPTS_COUNT_PREFIX + payload.quiz_id;
        let attempts = cookie.getCookieBase64(cookie_name);

        if (store.state.iframe_mode) {
            attempts = store.state.iframe_attempts;
        }

        if (attempts == undefined) {
            cookie.setCookieBase64(cookie_name, 0, 1);
        }
        if (attempts >= login_settings.attempts_allowed) {
            return false;
        }
    }
    return true;
}


export default router;
